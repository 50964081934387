@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  box-sizing: border-box;
}

.ant-form-item-explain-error {
  font-size: 0.6rem;
  margin: 0.2rem 0rem 0.6rem 0rem;
}

.ant-alert-message {
  font-weight: bold;
  font-size: 0.9rem !important;
}

.ant-dropdown-menu-title-content,
.rhap_time {
  font-size: 0.7rem !important;
}

.rhap_container {
  box-shadow: none !important;
}

.ant-image-mask-info {
  font-size: 0.5rem;
}
